import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeShelters = () => {
    return (
        <Layout>
                <Seo
      title="Abris vélos - BIKESAFE"
      description="Fini le temps où l'on devait accrocher son vélo à un arbre dans le parc ou à un lampadaire dans la rue. Aujourd'hui, il existe des solutions de rangement vélos et d'abris vélos sécurisées. Un abri ou un parc fermé pour vélos peut être installé dans la majorité des aires publiques. Avec de plus en plus de personnes qui utilisent leur vélo, les amateurs de cyclisme nécessitent et exigent encore plus de disposer d'abris vélos sécurisés et accessibles."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center uppercase">
                            Abris Vélos Et Parking Vélos
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <p className="flow-text">
                            Fini le temps où l'on devait accrocher son vélo à un arbre dans le parc ou à un lampadaire dans la rue. Aujourd'hui, il existe des solutions de rangement vélos et d'abris vélos sécurisées. Un abri ou un parc fermé pour vélos peut être installé dans la majorité des aires publiques. Avec de plus en plus de personnes qui utilisent leur vélo, les amateurs de cyclisme nécessitent et exigent encore plus de disposer d'abris vélos sécurisés et accessibles.
                        </p>
                        <p className="flow-text">Notre gamme diversifiée d'abris offre des solutions de rangement vélos ingénieuses. Elle se compose de modèles résistants, modernes et extrêmement pratiques. Quels que soient les dimensions et le style que vous recherchez, nous avons l'abri vélo qui vous convient !</p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                <hr/>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/233">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/233/homepage-233.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Abri Vélos New York Deux Niveaux</h3>
                            <p>Cet superbe abri vélos est livré avec un toit anti-escalade et est parfait pour les installations dans les espaces publics.</p>
                            <p className="shop-now">Obtenir Un Devis</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/38">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/38/homepage-38.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Abri en Arc Deux Niveaux</h3>
                            <p>Conçu pour doubler la capacité de rangement des vélos dans un espace relativement réduit avec ses deux rangées de stationnement. Abri disponible avec une finition galvanisée ou revêtement...</p>
                            <p className="shop-now">Obtenir Un Devis</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/22">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/22/homepage-22.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Abri Dijon Deux Niveaux</h3>
                            <p>Cet abri est doté des meilleures caractéristiques et de formes arrondies qui s'adaptent à tout type d'environnement. Un abri en acier doux avec fixation par boulonnage.</p>
                            <p className="shop-now">Obtenir Un Devis</p>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default BikeShelters;